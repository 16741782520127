import Vue from 'vue'

class PricingRepository {


  simulateComputePriceForSession(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/pricing/session-price-compute-dry-run',
        method: 'post',
        data, // must be {cdrId: 'xxxx'} or {sessionId: 'xxxx'}
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default new PricingRepository()