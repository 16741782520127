<template>
  <v-dialog v-model="sessionPriceComputeDryRunModal" max-width="600">
      <v-card class="session-price-compute-dry">
        <v-card-title>
          Price calculation verification
        </v-card-title>
        <v-card-text>


          <v-row>
            <v-col>
              <v-simple-table v-if="priceFormatted">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Old price</th>
                      <th>New price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, field) in priceFormatted" :key="field" :class="isSubTotal(field) ? 'subtotal' : ''">
                      <th>{{ field }}</th>
                      <td>
                        <template v-if="field === 'tariffName'">
                          <v-btn x-small target="_blank" v-if="sessionPrice.oldPrice?.tariffOrganizationId" :to="{name: 'organizations.tariffs', params: {id: sessionPrice.oldPrice?.tariffOrganizationId}}">
                            {{ row.old ? row.old + (sessionPrice.oldPrice?.tariffOperatorId ? ' (' + sessionPrice.oldPrice?.tariffOperatorId + ')' : '')  : 'No tariff'  }}
                          </v-btn>
                          <template v-else>
                            {{ row.old ? row.old + (sessionPrice.oldPrice?.tariffOperatorId ? ' (' + sessionPrice.oldPrice?.tariffOperatorId + ')' : '')  : 'No tariff'  }}
                          </template>
                        </template>
                        <template v-else>
                          {{ row.old }}{{ field === 'cpoMarginType' || field === 'cpoMarginRuleScenarioId' ? '' : ' €' }}
                        </template>
                      </td>
                      <td>
                        <template v-if="field === 'tariffName'">
                          <v-btn x-small target="_blank" v-if="sessionPrice.newPrice?.tariffOrganizationId" :to="{name: 'organizations.tariffs', params: {id: sessionPrice.newPrice?.tariffOrganizationId}}">
                            {{ row.new ? row.new + (sessionPrice.newPrice?.tariffOperatorId ? ' (' + sessionPrice.newPrice?.tariffOperatorId + ')' : '')  : 'No tariff'  }}
                          </v-btn>
                          <template v-else>
                            {{ row.new ? row.new + (sessionPrice.newPrice?.tariffOperatorId ? ' (' + sessionPrice.newPrice?.tariffOperatorId + ')' : '')  : 'No tariff'  }}
                          </template>
                        </template>
                        <template v-else>
                         {{ row.new }}{{ field === 'cpoMarginType' || field === 'cpoMarginRuleScenarioId' ? '' : ' €' }}
                        </template>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div class="font-weight-bold mt-6">
                CdrId : {{ data.cdrId }}
              </div>
            </v-col>
          </v-row>


        </v-card-text>
        <v-card-actions>
          <v-row class="ma-0">
            <v-col class="text-right">
              <v-btn small type="button" class="mr-3" @click="close">
                {{ $t('close') }}
              </v-btn>

            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
      <tariff-modal ref="tariffModal" />
  </v-dialog>
</template>

<script>
import TokenRepository from "@repository/TokenRepository";
import CustomerSelect from "@blocks/Select/CustomerSelect.vue";
import OrganizationSelect from "@blocks/Select/OrganizationSelect.vue";
import MemberSelect from "@blocks/Select/MemberSelect.vue";
import ReportRepository from "@repository/ReportRepository";
import PricingRepository from "@repository/PricingRepository";
import TariffModal from "@modals/TariffModal.vue";

export default {
  components: {TariffModal},

  data() {
    return {
      sessionPriceComputeDryRunModal: null,
      sessionPrice: null,
      data: null,
    }
  },

  props: {

  },

  watch: {
    sessionPriceComputeDryRunModal(open) {
      if(!open) {
        this.sessionPrice = null
        this.$emit('close')
      }
    }
  },

  computed: {
    priceFormatted() {
      const fields = [
        'tariffName',
        'cpoMarginRuleScenarioId',
        'cpoMarginType',
        'energyKwhPrice',
        'energyCost',
        'stationOwnerMargin',
        'stationOwnerPayout',
        'cpoMargin',
        'cpoPrice',
        'roamingCharges',
        'paymentProcessingFee',
        'invoicePaymentFee',
        'serviceCharges',
        'netChargingCost',
        'netExtraCharges',
        'netCost',
        'netKwhPrice',
        'valueAddedTax',
        'finalCost',
      ]

      const prices = {}
      if(this.sessionPrice) {
        fields.forEach(field => {
          if(!this.sessionPrice[field]) {
            prices[field] = {}
          }
          prices[field]['old'] = this.sessionPrice.oldPrice[field] ?? null
          prices[field]['new'] = this.sessionPrice.newPrice[field] ?? null
        })
      }

      return prices
    }
  },

  methods: {

    open(data) {
      this.data = data
      this.loadData(data).then(() => {
        this.sessionPriceComputeDryRunModal = true
      })
    },

    close() {
      this.sessionPriceComputeDryRunModal = false
    },

    isSubTotal(field) {
      return ['cpoPrice', 'finalCost', 'netCost',  'serviceCharges', 'cpoMarginRuleScenarioId', 'cpoMarginType', 'stationOwnerPayout'].includes(field)
    },


    loadData(data) {
      return new Promise((resolve, reject) => {
        this.showLoading(true)
        PricingRepository.simulateComputePriceForSession(data).then((data) => {
          this.sessionPrice = data
          this.showLoading(false)
          resolve(data)
        }).catch(err => {
          this.notifyError(err  )
          this.showLoading(false)
          reject(err)
        })
      })
    },

    openTariff(tariffId) {
      this.$refs.tariffModal.open(tariffId)
    }


  }
}
</script>

<style lang="scss">

.session-price-compute-dry {
  thead {
    th {
      border-bottom: 1px solid #000 !important;
      text-align: center;
    }
  }
  tbody {
    tr {
      &.subtotal {
        td, th {
          border-top: 1px dotted #ccc !important;
          font-weight: bold;
          color: red;
        }
        & + tr:not(.subtotal) {
          td, th {
            border-top: 1px dotted #ccc !important;
          }
        }
      }
    }
    th {
      border-right: 1px dotted #ccc !important;
    }
    td {
      text-align: center;
      & + td {
        border-left: 1px dotted #ccc !important;
      }
    }
  }

}
</style>
