<template>
  <div>
    <v-autocomplete
        ref="input"
        v-model="data"
        :label="label"
        :loading="loading"
        :items="items"
        v-on="$listeners"
        :rules="rules"
        :search-input.sync="searchText"
        :solo="solo"
        :clearable="clearable"
        :append-icon="appendIcon"
        :readonly="readonly"
        :disabled="disabled"
        :multiple="multiple"
        :chips="multiple"
        small-chips
        deletable-chips
        :placeholder="placeholder"
        @keydown="loadItems(searchText)"
        no-filter
        hide-details
        autocomplete="nope"
    >
    </v-autocomplete>
  </div>
</template>

<script>

import OperatorGroupRepository from "@repository/OperatorGroupRepository";

export default {
  data(){
    return {
      loading: false,
      items: [],
      data: this.value,
      searchText: "",
    }
  },

  model: {
    prop: 'value',
    event: 'input',
  },

  props: {
    value: [String, Array],
    type: String,
    clearable: Boolean,
    readonly: Boolean,
    disabled: Boolean,
    solo: Boolean,
    multiple: Boolean,
    rules: Array,
    appendIcon: String,
    placeholder: String,
    label: String,
    filters: Function,
    role: String,
  },

  watch: {

    data(val) {
      this.$emit('input', val)
      // this.loadItems(null, val)
    },

    value(val) {
      this.data = val
    }
  },

  mounted() {
    this.loadItems(null, this.data)
  },

  computed: {

  },

  methods: {
    loadItems(searchText, defaultValue) {

      if(typeof defaultValue === "string") {
        defaultValue = [defaultValue]
      }
      this.loading = true
      OperatorGroupRepository.search({
        searchText,
        role: this.role ?? null,
        limit: 25,
        offset: 0,
        sortBy: 'createdAt',
        sortOrder: 'desc',
      }).then(result => {
        let items = result.items
        if (this.filters) {
          items =  items.filter(this.filters)
        }

        this.items = items.map(item => {
          return {
            value: item._id,
            text: item.name + ' (' + item.role + ')',
          }
        })
        this.loading = false
      }).catch(err => {
        this.$dialog.notify.error(err.data.message ?? err.message)
      })
    },

    focus() {
      this.$refs.input.focus()
    }
  }
}
</script>

<style lang="scss">

</style>